.floating-btn {
    width: 100px;
    height: 100px;
    background: #076666;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 50%;
    color: white;
    font-size: 15px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
    position: fixed;
    right: 5%;
    top: 150px;
    transition: background 0.25s;
}
.floating-btn:active {
    background: #388484;
}
.floating-btn:hover {
    color: white;
}
#mydiv {
    position: absolute;
    z-index: 9;
    background-color: #f1f1f1;
    text-align: center;
    border: 1px solid #d3d3d3;
  }
@media (max-width: 1024px) {
    .floating-btn {
        right: 5%;
        top: 75%;
        width: 90px;
        height: 90px; 
        font-size: 14px;
    }
}