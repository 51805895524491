html,body {
  height: 100%;
  background-image: url("./fond.png");
}

body {
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

p {
  font-family: "Roboto", sans-serif;
}

li {
  font-family: "Roboto", sans-serif;
}

.content {
  flex: 1 0 auto;
  padding: 10px;
}

.pageError {
  width: 100%;
  margin-top: 20%;
  display: flex;
  position: fixed;
  text-align: center;
}

.scrollTop {
  position: fixed; 
  width: 100%;
  bottom: 20px;
  align-items: center;
  height: 20px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.2s;
  transition: opacity 0.4s;
  opacity: 0.5;
}

.scrollTop:hover{
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

@media (max-width: /*769px*/1025px) {
  .pageError {
      margin-top: 40%;
      text-align: center;
  }
}