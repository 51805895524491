.footer {
    background: #E2001B;
    color: white;
    flex-shrink: 0;
}
.erilia-footer-right {
    text-align: right;
}
.erilia-footer-left, .erilia-footer-right {
    float: left;
    width: 50%;
    padding: 0.5%;
}
.footer-text {
    color: white;
}
.footer-text:hover {
    color: white; 
    text-decoration: none;
}
.footer-img {
    height: 100px;
    margin-left: 20px;
    margin-bottom: 40px;
}