.position {
    padding: 1%;
  }
  .etude-css {
    margin-top: 10%;
  }
  .listeIdee {
    margin-top: 5%;
    text-align: center;
  }
  @media (max-width: 1025px) {
    .etude-css {
        margin-top: 35%;
    }
    .position {
        padding: 10%;
    }
    .listeIdee {
        margin-top: 15%;
    }
  }
/* Article_accueil.js */
.article {
    margin-top: 5%;
}
@media (max-width: 1025px) {
    .article {
      margin-top: 25%;
    }
}