/* Menu.js */
.menu-list {
    height: 100%;
    background: white;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    z-index: 200;
    transform: translateX(-100%); 
    transition: transform 0.3s ease-out; 
}
.menu-list.open{
    transform: translateX(0);
}
.menu-list ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}
.menu-list li {
    margin: 15%;
}
.menu-list a {
    color: #CF1515;
    text-decoration: none;
    font-size: 24px;
}
@media (min-width: 1025px) {
    .menu-list {
        display: none;
    }
}
/* MenuButton.js */
.toggle_button {
    display: flex; 
    flex-direction: column;
    justify-content: space-between; 
    height: 30px;
    width: 30px;
    background: transparent;
    border: none;
    padding: 0;
    box-sizing: border-box;
}
.toggle_button:focus {
    outline: none;
}
.toggle_button_line {
    width: 30px;
    height: 2px;
    background: black;
}
/* NavbarBootstrap.js */
.default {
    background-color: #E2001B;
    font-size: 20px;
    margin-top: 65px;
}
.default .navbar-nav .nav-link {
    color: white ;
}

.default .navbar-nav .nav-link:hover {
    color: white ;
}
.default .navbar-nav > li{
    padding-left:110px;
    padding-right:110px;
}
@media (max-width: 1024px) {
    .nav-item {
        display: none;
    }
}
@media (min-width: 1025px) {
    .toolbar_toggle_button {
        display: none;
    }
}
/* backDrop.js */
.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background: rgba(0,0,0,0.3);
    z-index: 100;
}
/* Header.js */
.header-img {
    margin-top: -8%;
    margin-left: 260%;
}
.header-google {
    margin-right: 15%;
}
.header-erilia {
    width:10%;
    height:10%;
    margin-left:16%; 
}
.header-nom {
    color: black;
    margin-right: 2px;
}
.header-nom:hover {
    color: black;
    text-decoration: none;
}
@media (max-width: 1025px) {
    .header-img {
        margin-left: -10%;
    }
    .header-google {
        display: none;
    }
}