.login {
    width: 100%;
    margin-top: 10%;
    display: flex;
    position: fixed;
    text-align: center;
    justify-content: center;
}
@media (max-width: 1025px) {
    .login {
        margin-top: 45%;
        text-align: center;
    }
    .title{
        font-size: 150%;
        text-align: center;
    }
}