/* Accueil.js */
.accueil-list {
    margin-top: 15%;
}
.header-card{
    background-color: rgb(8, 102, 102);   
}
@media (max-width: 1024px) {
     .accueil-list {
        padding: 10%;margin-top: 30%;
    } 
}
