.projet{
    margin-top:10%;
    text-align: center;
}
.projet-css {
    margin-top: 5%;
  }
.projet-list {
    padding: 1%;
}
.projet-listP {
    text-align: center;
    margin-top: 1%;
    background-color: white;
}
/* Participer.js */
.corps {
    width: 100%;
    margin-top: 10%;
    display: flex;
    justify-content: center;
  }
  .partie {
    text-align: center;
  }
  .boutonparticiper {
    display: block;
    margin: auto;
  }
  .linkp {
    color: white;
  }
  .linkp:hover {
    color: white;
  }
  @media (max-width: 1025px) {
    .corps {
        margin-top: 35%;
        margin-bottom: 100%;    
    }
  }
@media (max-width: 1025px) {
    .projet{
        margin-top:35%;
        margin-bottom: 100%;    
    }

    .projet-css {
        margin-top: 25%;
    }

    .projet-list {
        padding: 10%;
    }

    .projet-listP {
        margin-top: 4%;
    }
}