/* Idee.js */
.idee-css {
  margin-top: 5%;
}
@media (max-width: 1025px) {
  .idee-css {
    margin-top: 25%;  
  }
}
/* Etude.js & Realisation.js */
.position {
  padding: 1%;
}
.etude-css {
  margin-top: 10%;
}
.listeIdee {
  margin-top: 5%;
  text-align: center;
}
.etude-listP {
  text-align: center;
  margin-top: 1%;
  background-color: white;
}
@media (max-width: 1025px) {
  .etude-css {
      margin-top: 35%;
  }
  .position {
      padding: 10%;
  }
  .listeIdee {
      margin-top: 15%;
  }
}
/* Participer.js */
.corps {
  width: 100%;
  margin-top: 10%;
  display: flex;
  justify-content: center;
}
.partie {
  text-align: center;
}
.boutonparticiper {
  display: block;
  margin: auto;
}
.linkp {
  color: white;
}
.linkp:hover {
  color: white;
}
@media (max-width: 1025px) {
  .corps {
      margin-top: 35%;
      margin-bottom: 100%;    
  }
}