.ajoutcorps {
    width: 100%;
    margin-top: 10%;
    display: flex;
    justify-content: center;
}
.boutonadd {
    display: block;
    margin: auto;
}
.linkajout {
    color: white;
}
.linkajout:hover {
    color: white;
}
@media (max-width: 1025px) {
    .ajoutcorps {
        margin-top: 35%;
        margin-bottom: 100%;    
    }
}